export default {
  namespaced: true,
  state: {
    yqName: '', // 园区名称
    model: '1', // 1-vr 2-地图
  },
  mutations: {
    setYqName(state, yqName) {
      state.yqName = yqName;
    },
    setModel(state, model) {
      console.log(`garden setModel :>>`, model);
      state.model = model;
    },
  },
  actions: {},
};
